<template>
  <div id="app">
    <!-- <h1>{{ message }}</h1> -->
    <!-- <element-view></element-view> -->

    <!-- 员工管理 -->
    <!-- <emp-view></emp-view>
    <dept-view></dept-view> -->
    <router-view></router-view>
  </div>
</template>

<script>
// import EmpView from './views/tlias/EmpView.vue';
// import DeptView from './views/tlias/DeptView.vue';
// import ElementView from './views/element/ElementView.vue'
export default {
  components: {},
  data(){ //data: function(){
    return{
      message: "Hello Vue222",
    }
  },
  methods:{

  }
}


// 解决ERROR ResizeObserver loop completed with undelivered notifications.
// 重写ResizeObserver的构造函数，并在其中定义并调用防抖函数
window.ResizeObserver = class ResizeObserver extends window.ResizeObserver {
  constructor(callback) {
    let timer = null;
    const debouncedCallback = function () {
      let context = this;
      let args = arguments;
      clearTimeout(timer);
      timer = setTimeout(function () {
        callback.apply(context, args);
      }, 16);
    };
    super(debouncedCallback);
  }
}
</script>

<style>

</style>